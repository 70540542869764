import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "path": "/blog/sanitisation-policies",
  "date": "26th July 2022",
  "title": "What are Database Sanitisation Policies and how can I use them?",
  "wip": false,
  "draft": false,
  "summary": "Skpr now provides database sanitisation policies for Skpr-hosted applications.",
  "author": "Karl Hepworth",
  "tag": "Development",
  "tagColor": "blue",
  "tags": [{
    "name": "mysql"
  }, {
    "name": "backups"
  }, {
    "name": "docker images"
  }, {
    "name": "compliance"
  }, {
    "name": "dev tools"
  }]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Skpr now supports sanitisation policies. Discover what this means for you and your hosted applications.`}</p>
    <h2>{`What are sanitisation policies?`}</h2>
    <p>{`Sanitisation policies address Personally Identifiable Information (PII) privacy and compliance concerns, meaning you can
be sure database backups won’t store secure information.`}</p>
    <p>{`These policies provide a configuration which will remove or rewrite specific information in a database backup. Your
organisation and git workflow can control these rules.`}</p>
    <p>{`Given the database size is smaller, this approach also conserves bandwidth and improves local development speeds.`}</p>
    <h2>{`How it works`}</h2>
    <p>{`Applications on Skpr have benefited from MySQL sanitisation rules for some time, and we've extended this to be more
flexible and to support backups.`}</p>
    <p>{`With this latest release Skpr users can add a sanitisation policy to their environment that both MySQL images and
backups can utilise.`}</p>
    <p>{`If a sanitisation policy is not configured, Skpr will set a default policy for the application type.`}</p>
    <p><img parentName="p" {...{
        "src": "/images/posts/20220726/diagram.jpg",
        "alt": "Diagram demonstrating workflow of sanitisation policies on skpr"
      }}></img></p>
    <h2>{`When would I want to use sanitisation policies?`}</h2>
    <ul>
      <li parentName="ul">{`Restrict access to personal or sensitive information in a database. For example, webform submission data, user data or
data associated with custom functionality.`}</li>
      <li parentName="ul">{`Your organisation has compliance requirements for the sensitive information allowed in MySQL image backups.`}</li>
      <li parentName="ul">{`Ensure work performed in CI does not leak sensitive production data to non-production environments.`}</li>
      <li parentName="ul">{`Reduce the size of your database for better local environment performance and import times.`}</li>
    </ul>
    <h2>{`How can I set my own custom rules?`}</h2>
    <p>{`Skpr users can apply custom policies through your project’s configuration. You can view our
`}<a parentName="p" {...{
        "href": "https://docs.skpr.io/using-skpr/mysql/sanitization-policies"
      }}>{`technical documentation for more information`}</a>{`.`}</p>
    <h2>{`Wrapping up`}</h2>
    <p>{`The Skpr product roadmap has a strong focus on security, and we are excited to release this feature so our customers
can benefit from faster, more secure database images and backups.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      